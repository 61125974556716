import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { navigate } from 'gatsby';
import ClickableText from '~components/buttons/ClickableText';
import useCourseEligible from '~hooks/useCourseEligible';
import useCheckout from '~hooks/useCheckout';
import products from '~utils/products';

import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const getVariantStyles = variant => {
  switch (variant) {
    case 'large': {
      return { fontSize: '32px', lineHeight: '32px', padding: '25px 64px' };
    }
    case 'medium':
    default: {
      return { fontSize: '18px', lineHeight: '18px', padding: '20px 64px' };
    }
  }
};

const useStyles = makeStyles({
  ctaButton: props => ({
    fontWeight: '600',
    fontFamily: 'Inter',
    borderRadius: '40px',
    width: 'fit-content',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    backgroundColor: props.cta_background_color,
    color: props.cta_text_color,
    ...getVariantStyles(props.variant)
  })
});

const CourseCta = ({
  product_id,
  is_subscriber_eligible,
  is_producer_circle_eligible,
  variant,
  cta_text,
  eligible_cta_text,
  eligible_cta_url,
  cta_text_color,
  cta_background_color
}) => {
  const classes = useStyles({
    variant,
    cta_text_color,
    cta_background_color
  });
  const [, setCheckoutState] = useCheckout();
  const isCourseEligible = useCourseEligible(is_subscriber_eligible, is_producer_circle_eligible);
  const { isMarketingSite } = useConversionFramework();

  const onCtaClick = useCallback(() => {
    if (isCourseEligible) {
      // navigate to eligible_cta_url
      navigate(eligible_cta_url);
    } else {
      // navigate to checkout page
      const product = products.find(({ id }) => id === product_id);
      if (product && isMarketingSite) {
        redirectToMembersCheckout(product, 'year');
      } else if (product) {
        setCheckoutState({ product, billingCycle: 'year' });
        navigate('/checkout');
      }
    }
  }, [isCourseEligible, eligible_cta_url, setCheckoutState, product_id, isMarketingSite]);

  if (isCourseEligible === null) {
    return null;
  }

  return (
    <ClickableText onClick={onCtaClick} className={classes.ctaButton}>
      {isCourseEligible ? eligible_cta_text : cta_text}
    </ClickableText>
  );
};

CourseCta.propTypes = {
  product_id: PropTypes.string.isRequired,
  is_subscriber_eligible: PropTypes.string.isRequired,
  is_producer_circle_eligible: PropTypes.string.isRequired,
  cta_text: PropTypes.string.isRequired,
  eligible_cta_text: PropTypes.string.isRequired,
  eligible_cta_url: PropTypes.string.isRequired,
  cta_text_color: PropTypes.string,
  cta_background_color: PropTypes.string,
  variant: PropTypes.oneOf(['medium', 'large'])
};

CourseCta.defaultProps = {
  cta_text_color: '#FFFFFF',
  cta_background_color: '#000000',
  variant: 'medium'
};

export default CourseCta;
