import { useEffect, useState } from 'react';
import { useUserContext } from '~context/UserContext/UserSessionContext';

const useCourseEligible = (subscriberEligible, producerCircleEligible) => {
  const [eligible, setEligible] = useState(null);
  const { session } = useUserContext();
  const { isSubscriber, isProducer, isUserStateLoading } = session;

  useEffect(() => {
    if (!isUserStateLoading) {
      const isEligibleBySubscriber = subscriberEligible && isSubscriber;
      const isEligibleByProducer = producerCircleEligible && isProducer;
      setEligible(isEligibleBySubscriber || isEligibleByProducer);
    }
  }, [subscriberEligible, producerCircleEligible, isUserStateLoading, isSubscriber, isProducer]);

  return eligible;
};

export default useCourseEligible;
