import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Container from '~components/Container';
import RichText from '~components/RichText';
import CourseCtaButton from '~components/buttons/CourseCtaButton';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import useCourseEligible from '~hooks/useCourseEligible';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color || 'inherit',
    position: 'relative'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '72px 0px 65px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '40px 0px'
    }
  },
  heroImage: {
    display: 'flex',
    maxWidth: '600px',
    maxHeight: '450px',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      maxWidth: '460px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px'
    }
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '542px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  topLabel: props => ({
    backgroundColor: props.top_label_background_color,
    color: props.top_label_text_color,
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '700',
    padding: '10px 24px',
    fontFamily: 'Inter',
    width: 'fit-content',
    borderRadius: '10px',
    textAlign: 'center',
    margin: 0
  }),
  title: {
    color: props => props.title_text_color,
    lineHeight: '115%',
    fontSize: '44px',
    fontWeight: '700',
    fontFamily: 'Inter',
    margin: '24px 0px 16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      maxWidth: '317px'
    }
  },
  description: {
    marginBottom: '40px',
    '& > *': {
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: '500',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: props => props.description_text_color
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '317px'
    }
  }
}));

const CourseHero = ({
  product_id,
  is_subscriber_eligible,
  is_producer_circle_eligible,
  title,
  title_text_color,
  top_label,
  eligible_top_label,
  top_label_background_color,
  top_label_text_color,
  background_color,
  cta_text,
  eligible_cta_text,
  eligible_cta_url,
  cta_background_color,
  cta_text_color,
  description,
  description_text_color,
  image,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    background_color,
    top_label_background_color,
    top_label_text_color,
    title_text_color,
    description_text_color
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isCourseEligible = useCourseEligible(is_subscriber_eligible, is_producer_circle_eligible);
  const topLabel = isCourseEligible && eligible_top_label ? eligible_top_label : top_label;

  return (
    <div className={classes.root}>
      <Container center>
        <div className={classes.contentArea}>
          <div className={classes.textArea}>
            <p className={classes.topLabel}>{topLabel}</p>
            {isMobile && (
              <img src={image?.url} alt={image?.alt || title} className={classes.heroImage} />
            )}
            <h1 className={classes.title}>{title}</h1>
            <RichText
              html={description?.html}
              verticalSpacing={0}
              externalClassName={classes.description}
            />
            <CourseCtaButton
              variant="medium"
              product_id={product_id}
              is_subscriber_eligible={is_subscriber_eligible}
              is_producer_circle_eligible={is_producer_circle_eligible}
              cta_text={cta_text}
              eligible_cta_text={eligible_cta_text}
              eligible_cta_url={eligible_cta_url}
              cta_text_color={cta_text_color}
              cta_background_color={cta_background_color}
            />
          </div>
          {!isMobile && (
            <img src={image?.url} alt={image?.alt || title} className={classes.heroImage} />
          )}
        </div>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

CourseHero.propTypes = {
  product_id: PropTypes.string.isRequired,
  is_subscriber_eligible: PropTypes.string.isRequired,
  is_producer_circle_eligible: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  title_text_color: PropTypes.string.isRequired,
  top_label: PropTypes.string,
  eligible_top_label: PropTypes.string,
  top_label_background_color: PropTypes.string,
  top_label_text_color: PropTypes.string,
  background_color: PropTypes.string.isRequired,
  cta_text: PropTypes.string.isRequired,
  eligible_cta_text: PropTypes.string,
  eligible_cta_url: PropTypes.string.isRequired,
  cta_background_color: PropTypes.string.isRequired,
  cta_text_color: PropTypes.string.isRequired,
  description: PropTypes.shape({ html: PropTypes.string }).isRequired,
  description_text_color: PropTypes.string.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

CourseHero.defaultProps = {
  top_label: '',
  eligible_top_label: '',
  top_label_background_color: '#FFFFFF',
  top_label_text_color: '#000000',
  eligible_cta_text: '',
  bottom_shape_divider: null
};

export default CourseHero;
